const BotcenterBotConfig = (function () {
  const BotConstructor = function (id, bot_id, name, manualActivation) {
    this.id = id;
    this.bot_id = bot_id;
    this.name = name;
    this.allow_manual_activation = manualActivation;
  };
  BotConstructor.prototype = {
    serialize() {
      return {
        id: this.id,
        bot_id: this.bot_id,
        name: this.name,
        allow_manual_activation: this.allow_manual_activation
      }
    }
  };
  BotConstructor.parse = function ({
    id,
    bot_id,
    name,
    allow_manual_activation
  }) {
    return new BotConstructor(id, bot_id, name, allow_manual_activation)
  };
  return BotConstructor;
})();
