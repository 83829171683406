/**
 * @function trackSequence
 * @description Mantiene un buffer de teclas recientemente presionadas
 * @returns Handler del evento, para poder removerlo posteriormente si es necesario
 * @param {Function} onSequence - Callback a correr al completarse la secuencia dentro del timeout establecido
 */
function trackSequence(onSequence) {
  const whitelistedChars = /[\w\d]+/ig;
  const keystrokeDelay = 1000;
  let buffer = [];
  let lastKeyTime = Date.now();

  const sequenceHandler = event => {
    const key = event.which;

    if(typeof key !== 'number') {
      return;
    }
    
    const currentTime = Date.now();

    if (currentTime - lastKeyTime > keystrokeDelay) {
      buffer = [];
    }

    buffer.push(key);
    lastKeyTime = currentTime;

    onSequence(buffer);
  }

  document.addEventListener('keydown', sequenceHandler, { passive: true });

  return sequenceHandler;
}


/**
 * @function KeySequenceListener
 * @description Crea un EventListener para trackear una secuencia de teclas.
 * @returns Handler del evento, para poder removerlo posteriormente si es necesario
 * @param {Array<Number>} sequence - Secuencia de teclas a escuchar, pueden ser strings (['a', 'b']) o el valor numérico de una tecla ([66, 65])
 * @param {Function} callback - Callback a correr al completarse la secuencia
 * @example const listener = new KeySequenceListener([1, 2, 3], callback) // add
 * @example document.removeEventListener('keydown', listener) // remove
 */
function KeySequenceListener(sequence = [], callback = null) {
  const checkOnBufferUpdate = (buffer) => {
    if (sequence.length === buffer.length) {
      const arraysAreEqual = buffer.reduceRight((response, comparison, index) => {
        const itemsEquality = comparison == sequence[index];
        return response && itemsEquality;
      }, true);

      if (arraysAreEqual) {
        callback && callback();
      }
    }
  }

  return trackSequence(checkOnBufferUpdate);
}
