function getDeviceInfo() {
  const isMobile = window.innerWidth < 768;

  const userAgents = [
    { label: 'Win', value: 'Windows' },
    { label: 'Linux', value: 'Linux' },
    { label: 'Android', value: 'Android' },
    { label: 'like Mac', value: 'iOS' },
    { label: 'Mac', value: 'Mac' },
    { label: 'Chrome', value: 'Chrome OS' },
  ];

  const os = userAgents.find((ua) => (navigator.userAgent.includes(ua.label)))?.value || 'Unknown';

  const deviceInfo = {
    getInfo: navigator.userAgent,
    isMobile,
    os
  };
  return deviceInfo;
}
